// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.centeredtext {
    text-anchor: middle;
    dominant-baseline: middle;
}`, "",{"version":3,"sources":["webpack://./src/packages/graphvisualization/styles/node.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,yBAAyB;AAC7B","sourcesContent":[".centeredtext {\n    text-anchor: middle;\n    dominant-baseline: middle;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
