// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.submit-form {
    width: 100%;
}

/* input forms */
input[type=text], select {
    width: 80%;
    padding: 12px 16px;
    margin: 8px 6px;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

input[type=submit] {
    width: 18%;
    color: white;
    padding: 12px 16px;
    margin: 8px 0;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

input[type=submit]:disabled {
    background-color: var(--bg-disabled);
}

input[type=submit]:enabled {
    background-color: var(--bg);
}

input[type=submit]:hover:enabled {
    background-color: var(--bg-good);
    color: var(--text-color-dark)
}`, "",{"version":3,"sources":["webpack://./src/stylesheets/form.css"],"names":[],"mappings":"AAAA;IACI,WAAW;AACf;;AAEA,gBAAgB;AAChB;IACI,UAAU;IACV,kBAAkB;IAClB,eAAe;IACf,qBAAqB;IACrB,sBAAsB;IACtB,kBAAkB;IAClB,sBAAsB;AAC1B;;AAEA;IACI,UAAU;IACV,YAAY;IACZ,kBAAkB;IAClB,aAAa;IACb,YAAY;IACZ,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,oCAAoC;AACxC;;AAEA;IACI,2BAA2B;AAC/B;;AAEA;IACI,gCAAgC;IAChC;AACJ","sourcesContent":[".submit-form {\n    width: 100%;\n}\n\n/* input forms */\ninput[type=text], select {\n    width: 80%;\n    padding: 12px 16px;\n    margin: 8px 6px;\n    display: inline-block;\n    border: 1px solid #ccc;\n    border-radius: 4px;\n    box-sizing: border-box;\n}\n\ninput[type=submit] {\n    width: 18%;\n    color: white;\n    padding: 12px 16px;\n    margin: 8px 0;\n    border: none;\n    border-radius: 4px;\n    cursor: pointer;\n}\n\ninput[type=submit]:disabled {\n    background-color: var(--bg-disabled);\n}\n\ninput[type=submit]:enabled {\n    background-color: var(--bg);\n}\n\ninput[type=submit]:hover:enabled {\n    background-color: var(--bg-good);\n    color: var(--text-color-dark)\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
