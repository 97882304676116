// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logo {
    height: 60px;
    width: 60px;
    margin: 10px;
}

header {
    padding-bottom: 10px;
}

header>*>h1 {
    padding: 0 40px 0 0;
}

.headline {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
}

.headline>h1 {
    flex-grow: 1;
}`, "",{"version":3,"sources":["webpack://./src/components/headline/headline.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,oBAAoB;AACxB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,qBAAqB;IACrB,mBAAmB;AACvB;;AAEA;IACI,YAAY;AAChB","sourcesContent":[".logo {\n    height: 60px;\n    width: 60px;\n    margin: 10px;\n}\n\nheader {\n    padding-bottom: 10px;\n}\n\nheader>*>h1 {\n    padding: 0 40px 0 0;\n}\n\n.headline {\n    display: flex;\n    flex-flow: row nowrap;\n    align-items: center;\n}\n\n.headline>h1 {\n    flex-grow: 1;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
