// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Members block */
.container {
    display: flex;
    flex-flow: row wrap;
}

.element {
    width: 100%;
    padding: 5px;
    margin: 5px;
}

.demoicon {
    width: 60px;
    height: 60px;
    padding: 5px 0px 0px 0px;
}

.member {
    display: flex;
    flex-flow: column nowrap;
    text-align: center;
    align-items: center;
}

.profile {
    width: 100px;
    height: 100px;
    border-radius: 50px;
}

.role {
    font-size: 12px;
    color: grey;
}

.linklist {
    display: flex;
    flex-flow: row nowrap;
    padding: 8px;
}

.linkitem {
    width: 30px;
    width: 30px;
}

/* Demos block */
.demo {
    border: 1px solid rgb(180, 180, 180);
    border-radius: 5px;
    /*background-color: var(--accent);*/
    background-color: rgb(250, 250, 250);
    margin: 10px;
    padding: 5px;
    text-align: center;
}

.highlightable:hover {
    box-shadow: 0 0 15px 5px rgba(105, 105, 105, 0.6);
    transition: 0.3s;
}

/* MEDIA QUERIES */
@media (min-width: 800px) {
    .element {
        width: 45%;
    }
}

/*@media (min-width: 1000px) {
    .element {
        width: 30%;
    }
}*/

@media (min-width: 1200px) {
    .element {
        width: 30%;
    }
}`, "",{"version":3,"sources":["webpack://./src/stylesheets/containerlist.css"],"names":[],"mappings":"AAAA,kBAAkB;AAClB;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,WAAW;AACf;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,wBAAwB;AAC5B;;AAEA;IACI,aAAa;IACb,wBAAwB;IACxB,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,WAAW;AACf;;AAEA;IACI,aAAa;IACb,qBAAqB;IACrB,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,WAAW;AACf;;AAEA,gBAAgB;AAChB;IACI,oCAAoC;IACpC,kBAAkB;IAClB,mCAAmC;IACnC,oCAAoC;IACpC,YAAY;IACZ,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,iDAAiD;IACjD,gBAAgB;AACpB;;AAEA,kBAAkB;AAClB;IACI;QACI,UAAU;IACd;AACJ;;AAEA;;;;EAIE;;AAEF;IACI;QACI,UAAU;IACd;AACJ","sourcesContent":["/* Members block */\n.container {\n    display: flex;\n    flex-flow: row wrap;\n}\n\n.element {\n    width: 100%;\n    padding: 5px;\n    margin: 5px;\n}\n\n.demoicon {\n    width: 60px;\n    height: 60px;\n    padding: 5px 0px 0px 0px;\n}\n\n.member {\n    display: flex;\n    flex-flow: column nowrap;\n    text-align: center;\n    align-items: center;\n}\n\n.profile {\n    width: 100px;\n    height: 100px;\n    border-radius: 50px;\n}\n\n.role {\n    font-size: 12px;\n    color: grey;\n}\n\n.linklist {\n    display: flex;\n    flex-flow: row nowrap;\n    padding: 8px;\n}\n\n.linkitem {\n    width: 30px;\n    width: 30px;\n}\n\n/* Demos block */\n.demo {\n    border: 1px solid rgb(180, 180, 180);\n    border-radius: 5px;\n    /*background-color: var(--accent);*/\n    background-color: rgb(250, 250, 250);\n    margin: 10px;\n    padding: 5px;\n    text-align: center;\n}\n\n.highlightable:hover {\n    box-shadow: 0 0 15px 5px rgba(105, 105, 105, 0.6);\n    transition: 0.3s;\n}\n\n/* MEDIA QUERIES */\n@media (min-width: 800px) {\n    .element {\n        width: 45%;\n    }\n}\n\n/*@media (min-width: 1000px) {\n    .element {\n        width: 30%;\n    }\n}*/\n\n@media (min-width: 1200px) {\n    .element {\n        width: 30%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
