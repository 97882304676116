// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --bg: #242526;
  --bg-accent: #484a4d;
  --bg-good: #b4e61d;
  --bg-bad: #ed1c23;
  --bg-disabled: #c0c0c0;

  --text-color: #dadce1;
  --text-color-dark: #242526;
  --nav-size: 60px;
  --border: 1ps solid #474a4d;
  --border-radius: 8px;
  --speed: 500ms;
}

html {
  background: #f7f7f7;
}

body {
  font-size: 14px;
  font-family: 'Barlow', sans-serif;
  color: rgb(49, 46, 46);
  background: white;
  margin: 0 auto;
  padding: 50px 20px;
  text-align: center;
}

/* MEDIA QUERIES */
@media (min-width: 800px) {
  body {
    max-width: 1000px;
    text-align: left;
  }

  .smallscreen {
    display: none;
  }

  .largescreen {
    display: inherit;
  }
}

@media (max-width: 799px) {
  .smallscreen {
    display: inherit;
  }

  .largescreen {
    display: none;
  }
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,oBAAoB;EACpB,kBAAkB;EAClB,iBAAiB;EACjB,sBAAsB;;EAEtB,qBAAqB;EACrB,0BAA0B;EAC1B,gBAAgB;EAChB,2BAA2B;EAC3B,oBAAoB;EACpB,cAAc;AAChB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,iCAAiC;EACjC,sBAAsB;EACtB,iBAAiB;EACjB,cAAc;EACd,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA,kBAAkB;AAClB;EACE;IACE,iBAAiB;IACjB,gBAAgB;EAClB;;EAEA;IACE,aAAa;EACf;;EAEA;IACE,gBAAgB;EAClB;AACF;;AAEA;EACE;IACE,gBAAgB;EAClB;;EAEA;IACE,aAAa;EACf;AACF","sourcesContent":[":root {\n  --bg: #242526;\n  --bg-accent: #484a4d;\n  --bg-good: #b4e61d;\n  --bg-bad: #ed1c23;\n  --bg-disabled: #c0c0c0;\n\n  --text-color: #dadce1;\n  --text-color-dark: #242526;\n  --nav-size: 60px;\n  --border: 1ps solid #474a4d;\n  --border-radius: 8px;\n  --speed: 500ms;\n}\n\nhtml {\n  background: #f7f7f7;\n}\n\nbody {\n  font-size: 14px;\n  font-family: 'Barlow', sans-serif;\n  color: rgb(49, 46, 46);\n  background: white;\n  margin: 0 auto;\n  padding: 50px 20px;\n  text-align: center;\n}\n\n/* MEDIA QUERIES */\n@media (min-width: 800px) {\n  body {\n    max-width: 1000px;\n    text-align: left;\n  }\n\n  .smallscreen {\n    display: none;\n  }\n\n  .largescreen {\n    display: inherit;\n  }\n}\n\n@media (max-width: 799px) {\n  .smallscreen {\n    display: inherit;\n  }\n\n  .largescreen {\n    display: none;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
