// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Table listing items */
table {
    text-align: left;
    margin: 20px 0;
}

thead>tr>th {
    background: rgb(231, 231, 231);
    font-weight: bold;
}

.th_input {
    background: #d46371;
}

.th_output {
    background: #7fa2ff;
}

.t_span {
    width: 99%;
}

th, td {
    padding: 5px;
}

.tcol_icon {
    width: 20px;
    cursor: help;
    text-align: center;
}

.tcol_short {
    width: 40px;
}

.tcol_highlight {
    background-color: rgba(0, 225, 255, 0.1)
}

tbody>tr:hover {
    background: #f5f5f5;
}`, "",{"version":3,"sources":["webpack://./src/packages/suitevisualization/styles/table.css"],"names":[],"mappings":"AAAA,wBAAwB;AACxB;IACI,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,8BAA8B;IAC9B,iBAAiB;AACrB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI;AACJ;;AAEA;IACI,mBAAmB;AACvB","sourcesContent":["/* Table listing items */\ntable {\n    text-align: left;\n    margin: 20px 0;\n}\n\nthead>tr>th {\n    background: rgb(231, 231, 231);\n    font-weight: bold;\n}\n\n.th_input {\n    background: #d46371;\n}\n\n.th_output {\n    background: #7fa2ff;\n}\n\n.t_span {\n    width: 99%;\n}\n\nth, td {\n    padding: 5px;\n}\n\n.tcol_icon {\n    width: 20px;\n    cursor: help;\n    text-align: center;\n}\n\n.tcol_short {\n    width: 40px;\n}\n\n.tcol_highlight {\n    background-color: rgba(0, 225, 255, 0.1)\n}\n\ntbody>tr:hover {\n    background: #f5f5f5;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
