// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nav {
    height: 30px;
    display: flex;
    justify-content: space-between;
}

.navmenu {
    display: flex;
    align-items: center;

    @media screen and (max-width: 768px) {
        display: none;
    }
}

.navlink {
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 8px;
    height: 100%;
    cursor: pointer;

    &.active {
        color: #4d4dff;
    }
}`, "",{"version":3,"sources":["webpack://./src/stylesheets/navbar.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,8BAA8B;AAClC;;AAEA;IACI,aAAa;IACb,mBAAmB;;IAEnB;QACI,aAAa;IACjB;AACJ;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,qBAAqB;IACrB,cAAc;IACd,YAAY;IACZ,eAAe;;IAEf;QACI,cAAc;IAClB;AACJ","sourcesContent":[".nav {\n    height: 30px;\n    display: flex;\n    justify-content: space-between;\n}\n\n.navmenu {\n    display: flex;\n    align-items: center;\n\n    @media screen and (max-width: 768px) {\n        display: none;\n    }\n}\n\n.navlink {\n    display: flex;\n    align-items: center;\n    text-decoration: none;\n    padding: 0 8px;\n    height: 100%;\n    cursor: pointer;\n\n    &.active {\n        color: #4d4dff;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
