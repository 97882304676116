// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.annotation > text {
    font-family: monospace;
    font-size: 12px;
}

/* annotation label */
.annotation > .label {
    stroke-width: 0.75;
}
.annotation:hover > .label {
    stroke-width: 2px;
}

/* span within the sentence */
.annotation > .span {
    opacity: 0.1;
}
.annotation:hover > .span {
    opacity: 0.45;
}`, "",{"version":3,"sources":["webpack://./src/packages/labelvisualization/styles/annotation.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,eAAe;AACnB;;AAEA,qBAAqB;AACrB;IACI,kBAAkB;AACtB;AACA;IACI,iBAAiB;AACrB;;AAEA,6BAA6B;AAC7B;IACI,YAAY;AAChB;AACA;IACI,aAAa;AACjB","sourcesContent":[".annotation > text {\n    font-family: monospace;\n    font-size: 12px;\n}\n\n/* annotation label */\n.annotation > .label {\n    stroke-width: 0.75;\n}\n.annotation:hover > .label {\n    stroke-width: 2px;\n}\n\n/* span within the sentence */\n.annotation > .span {\n    opacity: 0.1;\n}\n.annotation:hover > .span {\n    opacity: 0.45;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
